/**
 * Mqtt
 * 核心依赖: [MQTT.js](https://github.com/mqttjs/MQTT.js)
 * 订阅方法，如
 * mqttInstance.subscribe('topic', (data) => {console.log(data)});
 */

import * as mqtt from 'mqtt/dist/mqtt.min';
import { v4 as uuidv4 } from 'uuid';

/** 连接选项 */
const options: mqtt.IClientOptions = {
  clean: false, // true: 清除会话, false: 保留会话
  keepalive: 30,
  connectTimeout: 4000,
  clientId: uuidv4(),
  properties: {
    sessionExpiryInterval: 3600, // 会话过期时间
  },
};

export class Mqtt {
  private client: mqtt.MqttClient;
  private listeners: { [key: string]: (message?: any) => void };

  constructor(connectUrl: string) {
    this.client = mqtt.connect(connectUrl, options);
    this.listeners = {};
    this.setListen();
  }

  setListen() {
    this.client.on('connect', () => {
      this.onConnect();
    });
    this.client.on('close', () => {
      this.onClose();
    });
    this.client.on('error', (error: Error) => {
      this.onError(error);
    });
    this.client.on('message', (topic: string, payload: Buffer) => {
      this.onMessage(topic, payload);
    });
  }

  onConnect() {
    console.log('--- mqtt connected ---');
  }

  onClose() {
    console.log('--- mqtt closed ---');
  }

  onError(error: Error) {
    console.log('error', error);
  }

  onMessage(topic: string, payload: Buffer) {
    try {
      if (this.listeners[topic]) {
        const message = payload.toString();
        this.listeners[topic](JSON.parse(message));
      }
    } catch (e) {
      console.log(e);
    }
  }

  onSend(topic: string, payload: any) {
    this.client.publish(topic, payload);
  }

  subscribe(topic: string, cb: (message?: any) => void) {
    if (!this.listeners[topic]) {
      // 防止重复订阅
      this.client.subscribe(topic, {
        qos: 2,
      });
    }
    this.listeners[topic] = cb;
  }

  unsubscribe(topic: string) {
    this.client.unsubscribe(topic);
    delete this.listeners[topic];
  }

  subscribeAll() {
    Object.keys(this.listeners).forEach(topic => {
      this.client.unsubscribe(topic);
    });
  }

  end() {
    this.client.end();
  }
}
